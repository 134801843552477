<template>
  <c-box flex-grow="1">
    <c-breadcrumb
      font-size="14px"
      line-height="21px"
      separator="›"
      :d="['none', 'block']"
    >
      <c-breadcrumb-item>
        <c-breadcrumb-link
          color="primary.400"
          as="router-link"
          to="/management/coupons"
        >
          Manajemen Kupon
        </c-breadcrumb-link>
      </c-breadcrumb-item>
      <c-breadcrumb-item>
        <c-breadcrumb-link is-current>
          {{ id ? "Edit" : "Tambah" }} Kupon
        </c-breadcrumb-link>
      </c-breadcrumb-item>
    </c-breadcrumb>
    <c-divider my="16px" />
    <c-flex
      mb="50px"
      justify="space-between"
    >
      <c-heading
        as="h3"
        font-family="Montserrat"
        font-weight="bold"
        font-size="24px"
        line-height="36px"
      >
        {{ id ? "Edit" : "Tambah" }} Kupon
      </c-heading>
      <c-flex align="center">
        <c-text
          v-if="isActiveCoupon"
          font-weight="500"
          font-size="12px"
          line-height="150%"
          color="darkGray.900"
          text-align="right"
          w="191px"
          mr="20px"
        >
          Masa berlaku kupon sudah dimulai. <br>
          Kupon tidak dapat di hapus.
        </c-text>
        <c-button
          v-if="id"
          :is-disabled="isActiveCoupon"
          variant="solid"
          variant-color="red"
          border-radius="60px"
          min-w="114px"
          h="auto"
          py="13px"
          px="18px"
          mr="20px"
          font-size="16px"
          font-weight="500"
          line-height="24px"
          @click="isOpen = true"
        >
          <c-image
            :src="require('@/assets/icon-trash.svg')"
            alt="file program"
            mr="10px"
            border-radius="6px"
          />
          Hapus
        </c-button>
      </c-flex>
    </c-flex>

    <form
      v-chakra
      max-w="590px"
    >
      <c-form-control
        mb="25px"
        is-required
        :is-read-only="isActiveCoupon"
        :is-invalid="name === ''"
      >
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          Nama Kupon
        </c-form-label>
        <c-input-group
          :background="isActiveCoupon ? 'superLightGray.900' : ''"
          size="md"
        >
          <c-input
            v-model="name"
            type="text"
            error-border-color="red.300"
            placeholder="Masukkan Nama Kupon"
            height="62px"
            :color="isActiveCoupon ? 'black' : 'inherit'"
          />
        </c-input-group>
      </c-form-control>

      <c-form-control
        :is-read-only="isActiveCoupon"
        :is-invalid="code === ''"
        is-required
        mb="25px"
      >
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          Kode
        </c-form-label>
        <c-input-group
          :background="isActiveCoupon ? 'superLightGray.900' : ''"
          size="md"
        >
          <c-input
            v-model="code"
            type="text"
            error-border-color="red.300"
            placeholder="Masukkan Kode"
            maxlength="10"
            height="62px"
            :color="isActiveCoupon ? 'black' : 'inherit'"
          />
        </c-input-group>
      </c-form-control>

      <c-form-control
        :is-read-only="isActiveCoupon"
        :is-invalid="quota === ''"
        is-required
        mb="25px"
      >
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          Kuota
        </c-form-label>
        <c-input-group
          :background="isActiveCoupon ? 'superLightGray.900' : ''"
          size="md"
        >
          <c-input
            v-model="quota"
            type="number"
            error-border-color="red.300"
            placeholder="Masukkan Kuota"
            height="62px"
            :color="isActiveCoupon ? 'black' : 'inherit'"
          />
        </c-input-group>
      </c-form-control>

      <c-grid
        template-columns="repeat(2, 1fr)"
        gap="6"
        mb="25px"
      >
        <c-form-control
          :is-read-only="isActiveCoupon"
          :is-invalid="startAt === ''"
          is-required
        >
          <c-form-label
            font-size="14px"
            color="#555555"
            font-weigh="400"
            font-family="Roboto"
          >
            Start Date
          </c-form-label>
          <c-input-group
            :background="isActiveCoupon ? 'superLightGray.900' : ''"
            size="md"
          >
            <c-input
              v-model="startAt"
              type="date"
              error-border-color="red.300"
              placeholder="Masukkan Start Date"
              height="62px"
              :color="isActiveCoupon ? 'black' : 'inherit'"
            />
          </c-input-group>
        </c-form-control>
        <c-form-control
          :is-read-only="isActiveCoupon"
          :is-invalid="endAt === ''"
          is-required
        >
          <c-form-label
            font-size="14px"
            color="#555555"
            font-weigh="400"
            font-family="Roboto"
          >
            End Date
          </c-form-label>
          <c-input-group
            :background="isActiveCoupon ? 'superLightGray.900' : ''"
            size="md"
          >
            <c-input
              v-model="endAt"
              type="date"
              error-border-color="red.300"
              placeholder="Masukkan End Date"
              height="62px"
              :color="isActiveCoupon ? 'black' : 'inherit'"
            />
          </c-input-group>
        </c-form-control>
      </c-grid>

      <c-flex mb="25px">
        <c-form-control
          w="110px"
          mr="24px"
          :is-read-only="isActiveCoupon"
          :is-invalid="type === ''"
          is-required
        >
          <c-form-label
            font-size="14px"
            color="#555555"
            font-weigh="400"
            font-family="Roboto"
          >
            Tipe
          </c-form-label>
          <c-input-group
            :background="isActiveCoupon ? 'superLightGray.900' : ''"
            size="md"
          >
            <c-select
              v-model="type"
              placeholder="Masukkan Tipe Kupon"
              height="62px"
              :color="isActiveCoupon ? 'black' : 'inherit'"
            >
              <option value="fix">
                Fix
              </option>
              <option value="percent">
                %
              </option>
            </c-select>
          </c-input-group>
        </c-form-control>
        <c-form-control
          flex="1"
          :is-read-only="isActiveCoupon"
          :is-invalid="totalDiscount === ''"
          is-required
        >
          <c-form-label
            font-size="14px"
            color="#555555"
            font-weigh="400"
            font-family="Roboto"
          >
            Jumlah Potongan
          </c-form-label>
          <c-input-group
            :background="isActiveCoupon ? 'superLightGray.900' : ''"
            size="md"
          >
            <c-input-left-addon h="62px">
              {{ type === "fix" ? "Rp" : "%" }}
            </c-input-left-addon>
            <c-input
              v-model="totalDiscount"
              type="number"
              error-border-color="red.300"
              :placeholder="`Masukkan ${
                type === 'fix' ? 'Jumlah' : '%'
              } Potongan`"
              min="1"
              max="3"
              height="62px"
              :color="isActiveCoupon ? 'black' : 'inherit'"
            />
          </c-input-group>
        </c-form-control>
      </c-flex>

      <c-form-control
        is-required
        mb="25px"
      >
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          Gunakan untuk program
        </c-form-label>
        <c-input-group size="md">
          <c-checkbox-group
            v-model="productIds"
            variant-color="green"
            :default-value="productIds"
          >
            <c-checkbox
              v-for="(item, index) in products"
              :key="index"
              :value="item.id"
              :is-disabled="isActiveCoupon"
            >
              {{ item.name }}
            </c-checkbox>
          </c-checkbox-group>
        </c-input-group>
      </c-form-control>

      <c-flex
        mt="28px"
        justify-content="space-between"
      >
        <c-button
          variant="solid"
          variant-color="gray"
          border-radius="60px"
          w="50%"
          h="62px"
          mr="20px"
          font-size="18px"
          color="#555555"
          @click="onCancel"
        >
          Batal
        </c-button>
        <c-button
          variant="solid"
          variant-color="primary"
          border-radius="60px"
          w="50%"
          h="62px"
          font-size="18px"
          :is-disabled="isDisabledSubmit || isActiveCoupon"
          @click="onSubmit"
        >
          Simpan
        </c-button>
      </c-flex>
    </form>

    <DeleteConfirm
      :content="deletedText"
      :is-open="isOpen"
      @on-delete="onDelete"
    />
  </c-box>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import generalMixin from '@/utils/general-mixins'
import DeleteConfirm from './../delete-confirm.vue'
import Moment from 'moment'
import { extendMoment } from 'moment-range'

const moment = extendMoment(Moment)

export default {
  name: 'SuperadminCouponsForm',
  components: { DeleteConfirm },
  mixins: [generalMixin],
  data() {
    return {
      isOpen: false,
      name: null,
      code: null,
      type: 'fix',
      totalDiscount: null,
      startAt: null,
      endAt: null,
      quota: null,
      productIds: [],
    }
  },
  computed: {
    ...mapGetters({
      axios: 'axios',
      item: 'suCoupons/item',
      products: 'clients/products',
    }),
    id() {
      return this.$route.params.id
    },
    isDisabledSubmit() {
      if (
        !this.name ||
        !this.code ||
        !this.type ||
        !this.totalDiscount ||
        !this.startAt ||
        !this.endAt ||
        !this.quota ||
        this.productIds.length === 0
      )
        return true
      return false
    },
    isActiveCoupon() {
      if (!this.id) return false
      const date = new Date()
      const start = new Date(this.startAt)
      const end = new Date(this.endAt)
      const range = moment.range(start, end)
      return range.contains(date)
    },
    deletedText() {
      return `Anda yakin ingin hapus kupon <br /> “${this.item.code}”?`
    },
  },
  watch: {
    item: {
      handler(val) {
        if (val) {
          this.name = val.name
          this.code = val.code
          this.type = val.type
          this.totalDiscount = val.totalDiscount
          this.startAt = val.startAt
          this.endAt = val.endAt
          this.quota = val.quota
          this.productIds = val.productIds
        }
      },
    },
  },
  async mounted() {
    this.loadProducts()
    if (!this.id) return
    this.detailCoupons(this.id)
  },
  methods: {
    ...mapActions({
      createCoupons: 'suCoupons/createCouponsSuperadmin',
      detailCoupons: 'suCoupons/detailCouponsSuperadmin',
      updateCoupons: 'suCoupons/updateCouponsSuperadmin',
      deleteCoupons: 'suCoupons/deleteCouponsSuperadmin',
      loadProducts: 'clients/getProducts',
    }),
    onSubmit() {
      let params = {
        name: this.name,
        code: this.code,
        type: this.type,
        totalDiscount: this.totalDiscount,
        startAt: this.startAt,
        endAt: this.endAt,
        quota: this.quota,
        productIds: this.productIds,
      }
      if (this.id) {
        params.id = this.id
        return this.updateCoupons(params)
          .then(() => {
            this.$toast({
              title: 'Success',
              description: 'Update coupon is successfully',
              status: 'success',
              duration: 5000,
              position: 'bottom-right',
              variant: 'subtle',
            })
            this.$router.push({
              name: 'superadmin.coupons.detail',
              params: { id: this.id },
            })
          })
          .catch((err) => {
            this.$toast({
              title: 'Failed',
              description: err.data.message ?? 'Ops! Something when wrong.',
              status: 'error',
              duration: 5000,
              position: 'bottom-right',
              variant: 'subtle',
            })
          })
      }
      return this.createCoupons(params)
        .then(() => {
          this.$toast({
            title: 'Success',
            description: 'Create coupon is successfully',
            status: 'success',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
          this.$router.push({
            name: 'superadmin.coupons',
            query: { type: this.type },
          })
        })
        .catch((err) => {
          this.$toast({
            title: 'Failed',
            description: err.data.message ?? 'Ops! Something when wrong.',
            status: 'error',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
        })
    },
    onCancel() {
      // if (this.id) {
      //   this.$router.push({
      //     name: "superadmin.coupons.detail",
      //     params: { id: this.id },
      //   });
      // }
      this.$router.go(-1)
    },
    onDelete(state) {
      this.isOpen = state
      if (!this.isOpen) return
      this.deleteCoupons(this.id)
        .then(() => {
          this.$toast({
            title: 'Success',
            description: 'Kupon has been deleted.',
            status: 'success',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
          this.$router.push({
            name: 'superadmin.coupons',
          })
        })
        .catch((err) => {
          this.$toast({
            title: 'Failed',
            description: err.data.message ?? 'Ops! Something when wrong.',
            status: 'error',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
        })
    },
  },
}
</script>
