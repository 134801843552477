var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "flex-grow": "1"
    }
  }, [_c('c-breadcrumb', {
    attrs: {
      "font-size": "14px",
      "line-height": "21px",
      "separator": "›",
      "d": ['none', 'block']
    }
  }, [_c('c-breadcrumb-item', [_c('c-breadcrumb-link', {
    attrs: {
      "color": "primary.400",
      "as": "router-link",
      "to": "/management/coupons"
    }
  }, [_vm._v(" Manajemen Kupon ")])], 1), _c('c-breadcrumb-item', [_c('c-breadcrumb-link', {
    attrs: {
      "is-current": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.id ? "Edit" : "Tambah") + " Kupon ")])], 1)], 1), _c('c-divider', {
    attrs: {
      "my": "16px"
    }
  }), _c('c-flex', {
    attrs: {
      "mb": "50px",
      "justify": "space-between"
    }
  }, [_c('c-heading', {
    attrs: {
      "as": "h3",
      "font-family": "Montserrat",
      "font-weight": "bold",
      "font-size": "24px",
      "line-height": "36px"
    }
  }, [_vm._v(" " + _vm._s(_vm.id ? "Edit" : "Tambah") + " Kupon ")]), _c('c-flex', {
    attrs: {
      "align": "center"
    }
  }, [_vm.isActiveCoupon ? _c('c-text', {
    attrs: {
      "font-weight": "500",
      "font-size": "12px",
      "line-height": "150%",
      "color": "darkGray.900",
      "text-align": "right",
      "w": "191px",
      "mr": "20px"
    }
  }, [_vm._v(" Masa berlaku kupon sudah dimulai. "), _c('br'), _vm._v(" Kupon tidak dapat di hapus. ")]) : _vm._e(), _vm.id ? _c('c-button', {
    attrs: {
      "is-disabled": _vm.isActiveCoupon,
      "variant": "solid",
      "variant-color": "red",
      "border-radius": "60px",
      "min-w": "114px",
      "h": "auto",
      "py": "13px",
      "px": "18px",
      "mr": "20px",
      "font-size": "16px",
      "font-weight": "500",
      "line-height": "24px"
    },
    on: {
      "click": function click($event) {
        _vm.isOpen = true;
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-trash.svg'),
      "alt": "file program",
      "mr": "10px",
      "border-radius": "6px"
    }
  }), _vm._v(" Hapus ")], 1) : _vm._e()], 1)], 1), _c('form', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "max-w": "590px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "mb": "25px",
      "is-required": "",
      "is-read-only": _vm.isActiveCoupon,
      "is-invalid": _vm.name === ''
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Nama Kupon ")]), _c('c-input-group', {
    attrs: {
      "background": _vm.isActiveCoupon ? 'superLightGray.900' : '',
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Nama Kupon",
      "height": "62px",
      "color": _vm.isActiveCoupon ? 'black' : 'inherit'
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-read-only": _vm.isActiveCoupon,
      "is-invalid": _vm.code === '',
      "is-required": "",
      "mb": "25px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Kode ")]), _c('c-input-group', {
    attrs: {
      "background": _vm.isActiveCoupon ? 'superLightGray.900' : '',
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Kode",
      "maxlength": "10",
      "height": "62px",
      "color": _vm.isActiveCoupon ? 'black' : 'inherit'
    },
    model: {
      value: _vm.code,
      callback: function callback($$v) {
        _vm.code = $$v;
      },
      expression: "code"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-read-only": _vm.isActiveCoupon,
      "is-invalid": _vm.quota === '',
      "is-required": "",
      "mb": "25px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Kuota ")]), _c('c-input-group', {
    attrs: {
      "background": _vm.isActiveCoupon ? 'superLightGray.900' : '',
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Kuota",
      "height": "62px",
      "color": _vm.isActiveCoupon ? 'black' : 'inherit'
    },
    model: {
      value: _vm.quota,
      callback: function callback($$v) {
        _vm.quota = $$v;
      },
      expression: "quota"
    }
  })], 1)], 1), _c('c-grid', {
    attrs: {
      "template-columns": "repeat(2, 1fr)",
      "gap": "6",
      "mb": "25px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "is-read-only": _vm.isActiveCoupon,
      "is-invalid": _vm.startAt === '',
      "is-required": ""
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Start Date ")]), _c('c-input-group', {
    attrs: {
      "background": _vm.isActiveCoupon ? 'superLightGray.900' : '',
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "date",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Start Date",
      "height": "62px",
      "color": _vm.isActiveCoupon ? 'black' : 'inherit'
    },
    model: {
      value: _vm.startAt,
      callback: function callback($$v) {
        _vm.startAt = $$v;
      },
      expression: "startAt"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-read-only": _vm.isActiveCoupon,
      "is-invalid": _vm.endAt === '',
      "is-required": ""
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" End Date ")]), _c('c-input-group', {
    attrs: {
      "background": _vm.isActiveCoupon ? 'superLightGray.900' : '',
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "date",
      "error-border-color": "red.300",
      "placeholder": "Masukkan End Date",
      "height": "62px",
      "color": _vm.isActiveCoupon ? 'black' : 'inherit'
    },
    model: {
      value: _vm.endAt,
      callback: function callback($$v) {
        _vm.endAt = $$v;
      },
      expression: "endAt"
    }
  })], 1)], 1)], 1), _c('c-flex', {
    attrs: {
      "mb": "25px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "w": "110px",
      "mr": "24px",
      "is-read-only": _vm.isActiveCoupon,
      "is-invalid": _vm.type === '',
      "is-required": ""
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Tipe ")]), _c('c-input-group', {
    attrs: {
      "background": _vm.isActiveCoupon ? 'superLightGray.900' : '',
      "size": "md"
    }
  }, [_c('c-select', {
    attrs: {
      "placeholder": "Masukkan Tipe Kupon",
      "height": "62px",
      "color": _vm.isActiveCoupon ? 'black' : 'inherit'
    },
    model: {
      value: _vm.type,
      callback: function callback($$v) {
        _vm.type = $$v;
      },
      expression: "type"
    }
  }, [_c('option', {
    attrs: {
      "value": "fix"
    }
  }, [_vm._v(" Fix ")]), _c('option', {
    attrs: {
      "value": "percent"
    }
  }, [_vm._v(" % ")])])], 1)], 1), _c('c-form-control', {
    attrs: {
      "flex": "1",
      "is-read-only": _vm.isActiveCoupon,
      "is-invalid": _vm.totalDiscount === '',
      "is-required": ""
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Jumlah Potongan ")]), _c('c-input-group', {
    attrs: {
      "background": _vm.isActiveCoupon ? 'superLightGray.900' : '',
      "size": "md"
    }
  }, [_c('c-input-left-addon', {
    attrs: {
      "h": "62px"
    }
  }, [_vm._v(" " + _vm._s(_vm.type === "fix" ? "Rp" : "%") + " ")]), _c('c-input', {
    attrs: {
      "type": "number",
      "error-border-color": "red.300",
      "placeholder": "Masukkan ".concat(_vm.type === 'fix' ? 'Jumlah' : '%', " Potongan"),
      "min": "1",
      "max": "3",
      "height": "62px",
      "color": _vm.isActiveCoupon ? 'black' : 'inherit'
    },
    model: {
      value: _vm.totalDiscount,
      callback: function callback($$v) {
        _vm.totalDiscount = $$v;
      },
      expression: "totalDiscount"
    }
  })], 1)], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "mb": "25px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Gunakan untuk program ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-checkbox-group', {
    attrs: {
      "variant-color": "green",
      "default-value": _vm.productIds
    },
    model: {
      value: _vm.productIds,
      callback: function callback($$v) {
        _vm.productIds = $$v;
      },
      expression: "productIds"
    }
  }, _vm._l(_vm.products, function (item, index) {
    return _c('c-checkbox', {
      key: index,
      attrs: {
        "value": item.id,
        "is-disabled": _vm.isActiveCoupon
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1)], 1), _c('c-flex', {
    attrs: {
      "mt": "28px",
      "justify-content": "space-between"
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "gray",
      "border-radius": "60px",
      "w": "50%",
      "h": "62px",
      "mr": "20px",
      "font-size": "18px",
      "color": "#555555"
    },
    on: {
      "click": _vm.onCancel
    }
  }, [_vm._v(" Batal ")]), _c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "primary",
      "border-radius": "60px",
      "w": "50%",
      "h": "62px",
      "font-size": "18px",
      "is-disabled": _vm.isDisabledSubmit || _vm.isActiveCoupon
    },
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm._v(" Simpan ")])], 1)], 1), _c('DeleteConfirm', {
    attrs: {
      "content": _vm.deletedText,
      "is-open": _vm.isOpen
    },
    on: {
      "on-delete": _vm.onDelete
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }